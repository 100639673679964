@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900");
*,
*:after,
*:before {
	padding: 0;
	margin: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	
	font-family: "Roboto", sans-serif;
}
.container {
	right: 0;
	left: 0;
	height: 360px;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	background: #fff;
	-webkit-box-shadow: 0px 0px 20px 5px rgba(135, 143, 146, 0.3);
	box-shadow: 0px 0px 20px 5px rgba(135, 143, 146, 0.3);
	margin: auto 5px auto 5px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.upperCard {
	background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/990368/BMW.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	position: relative;
	height: 205px;
	width: 100%;
	color: #fff;
	padding: 65px 0 0 30px;
}
.upperCard h1 {
	font-size: 25px;
}
.upperCard p {
	margin-top: 10px;
	font-weight: lighter;
	font-size: 14px;
	line-height: 1.8;
}

.lowerCard {
	overflow: visible;
	position: relative;
	height: 155px;
	width: 100%;
	background: #fff;
	padding-left: 30px;
	padding-right: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
#button {
	font-size: 16.5px;
	font-weight: 700;
	border-radius: 25px;
	border: none;
	padding: 15px;
	background: #9c68f0;
	color: white;
	text-decoration: n;
	letter-spacing: 0.3px;
	cursor: pointer;
	-webkit-transition: background 0.5s;
	-o-transition: background 0.5s;
	transition: background 0.5s;
	-webkit-box-shadow: 0px 2px 2px #dbc3ff;
	box-shadow: 0px 2px 2px #dbc3ff;
	outline: none;
	margin-bottom:10px;
}

.lowerCard button:hover {
	-webkit-transition: background 0.3s;
	-o-transition: background 0.3s;
	transition: background 0.3s;
	background: #a983f0;
}
.lowerCard ul {
	width: 100%;
	list-style-type: none;
}
.lowerCard .rHeading {
	position: relative;
	font-size: 15px;
	color: rgba(0, 0, 0, 0.4);
}
.lowerCard .rHeading:after,
.lowerCard .rHeading:before {
	position: absolute;
}
.lowerCard .rHeading:before {
	bottom: 3px;
	left: 0;
	white-space: nowrap;
	float: left;
	content: "................................................................."
		".................................................................";
}
.lowerCard .rHeading span {
	background: #fff;
	z-index: 999;
	position: relative;
}
.lowerCard .rHeading span:first-child {
	padding-right: 10px;
	color: #000;
}
.lowerCard .rHeading span:last-child {
	padding-left: 10px;
	float: right;
	font-weight: 700;
	font-size: 13px;
	padding-top: 2px;
}
.lowerCard .rDescription {
	overflow: visible;
	margin-top: 10px;
	font-size: 11px;
	font-weight: 600;
	line-height: 1.5;
	color: rgba(0, 0, 0, 0.7);
}
.lowerCard .rDescription span {
	float: right;
	font-size: 11px;
	font-weight: 900;
	margin-top: 0px;
	color: #000;
}
.lowerCard .rDescription b {
	font-size: 11px;
	color: #000;
}

@media only screen and (min-width: 450px) {
	.container {
		margin: auto 15px auto 15px;
	}
	.upperCard {
		padding: 65px 0 0 70px;
	}
	.lowerCard {
		padding-left: 70px;
		padding-right: 70px;
	}
	.lowerCard button {
		left: 70px;
	}
	.lowerCard .rDescription span {
		margin-top: -8px;
	}
	.lowerCard .rDescription b {
		font-size: 17px;
	}
}

@media only screen and (min-width: 600px) {
	.container {
		width: 550px;
		margin: 0;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
}

@media only screen and (min-width: 700px) {
	.container {
		width: 620px;
	}
}
