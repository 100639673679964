.container {
    width: 100%;
    margin: 0px auto;
    /* position: relative; */
}
.signupContainer{
    width: 100%;
    overflow-y: scroll;
    height: 100vh;
}
.createNewAccount {
    display: grid;
    justify-content: space-around; 
}
.btn{
    margin-top: 15px
}
.error {
    color: red;
  }
  .secureIcone{
    padding-left: 5px;
  }