@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

.login {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
  font-family: 'Comfortaa';
}
.Login__container{
  display: flex;
  flex-direction: column;
}
.imageContainer{
  display: flex;
   justify-content: center;
}
img{
  width: 292px;
  height: 105px;
  align-items: center;
}
.formContainer {
  width: auto;
  margin: 0 25%;
  padding: 30px 50px;
  background: #FFFFFF;
  border-radius: 18px;
  align-items: center;
}
.formHeading > h1{
  height: 57px;
  width: 104px;
  left: 669px;
  top: 374px;
  border-radius: nullpx;
}
.formHeading {
  display: flex; justify-content: center
}
.formCheckBox {
  display: flex;
  justify-content: center;
  margin-top:20px
}
.formLoginContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px
}
.formLoginButton {
  background-color: #343d73; width: 300px
}
.formForgetPassword {
  display: flex;
  justify-content: center;
  margin-top:20px;
  cursor:pointer;
}
.formForgetPassword:hover{
  color: #4b6cb7;
}
.login-formField{
  text-align: center;
}
.login-formField:hover{
  color: #4b6cb7;
}
.formStillNoAccount {
  display: flex;
  justify-content: center;
  margin-top: 20px
}
.formStillNoAccount span {
  padding-top: 12px
}
.formStillNoAccountButton{
  background-color: rgb(123 125 137);
  width: 150px;
  margin: 5px
}
.form {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  border-radius: 10px;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
}

.form input {
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: 'Comfortaa', cursive;
}

.form input:focus {
  background: #dbdbdb;
}

.form button {
  font-family: 'Comfortaa', cursive;
  text-transform: uppercase;
  outline: 0;
  background: #4b6cb7;
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.form button:active {
  background: #395591;
}

.form span {
  font-size: 75px;
  color: #4b6cb7;
}
.heading{
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
.icon{
  padding-bottom: 5px;
  padding-top: 5px;
  display: flex;
  justify-content: space-around;
}
.title{
  padding-top: 5px;
  padding-bottom: 5px;
  text-transform: capitalize;
  text-align: center;
}
.btn{
  display: flex;
  justify-content: space-around;
}

#selecteDomain{
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

#selecteDomain option:focus {
  background: #dbdbdb;
}