
  .sidebar {
    width:max(250px, 20vw);
    background : rgb(217 220 239);
    height:100vh;
  } 
  
  .sidebar .side-header {
    margin:0 auto;
    padding:1em 4em 1em 0em;
    width:max-content;
    background-image: url("https://www.iconpacks.net/icons/1/free-icon-pie-chart-660.png");
    background-repeat:no-repeat;
    background-position:right center;
    background-size:auto 90%;
  }
  
  .side-header h1,.side-header p {
    margin:0;
    color:white;
  }
  
  .side-header p {
    text-align:right;
  }
  
  
  .sidenav {
    display:flex;
    flex-direction:column;
    width:100%;
    height:80%;
    position:relative;
    margin-top: 0.3em;
  }
  
  .sidenav .button-container {
      width: 100%;
      display: contents;
  }
  
  .sidenav .button {
    padding:1em;
    position:relative;
    z-index:2;
    display:flex;
    align-items: center;
    gap:0.2em;
  }
  
  .button:nth-child(10) {
    margin-top:auto;
  }
  
  .sidenav .checkbox {
    position:absolute;
    z-index:-1;
  }
  
  .slide {
    padding:1.74em 0;
    background:lightgrey;
    width:100%;
    position:absolute;
    top:0;
    z-index:1;
    opacity:0.5;
    transition:all 0.5s;
        background: linear-gradient(45deg, lightgrey, transparent);
        box-sizing: border-box;
      border-left: solid 0.5em royalblue;
    transition-timing-function: cubic-bezier(0, 1.04, 0, 1.14);
  }
  
  [id="0"]:checked ~ .slide {
    --step-:0;
  }
  [id="1"]:checked ~ .slide {
    --step-:1;
  }
  [id="2"]:checked ~ .slide {
    --step-:2;
  }
  [id="3"]:checked ~ .slide {
    --step-:3;
  }
  [id="4"]:checked ~ .slide {
    top:calc(100% - 3.55em);
  }
  
  .sidenav .checkbox:checked ~ .slide {
      transform: translateY(calc(var(--step-) * 100%));
  }

   .gridIcon{
    position: absolute;
    visibility: hidden;
  } 
   .showSideBar{
    display: show;
  } 

   @media (max-width:670px){
    .sideBar{
      display: none;
    }
    .gridIcon{
      visibility: visible;
      top:20px;
      left:20px;
      position: absolute;
      z-index:1;
    }
    .gridIconColor{
      visibility: visible;
      top:20px;
      left:218px;
      position: absolute;
      z-index:1;
      color:white;
    }
    .showSideBar{
      display: none;
      /* z-index:2; */
    }
  } 

  .sidebar_items{
    display: flex;
  }

  .sidebar_items > svg{
    margin: 11px;
  }
.fXSNkt{
  background: #2a3454 !important;
}