

.main-head {
	background: #0D1440;
	box-shadow: 0px 1px 10px -6px rgba(0, 0, 0, .15);
	padding: 1rem;
	margin-bottom: 0;
	margin-top: 5rem;
	color: #fff;
	font-weight: 500;
	text-transform: uppercase;
	border-radius: 4px;
	font-size: 16px;
}

.pricing-table {
	background: #fff;
	box-shadow: 0px 1px 10px -6px rgba(0, 0, 0, .15);
	padding: 2rem;
	border-radius: 4px;
	transition: .3s;
}
.pricing-table:hover {
	box-shadow: 0px 1px 10px -4px rgba(0, 0, 0, .15);
}

.pricing-table .pricing-label {
	border-radius: 2px;
	padding: .25rem .5rem;
	margin-bottom: 1rem;
	display: inline-block;
	font-size: 12px;
	font-weight: 500;
}

.pricing-table h2 {
	color: #3b3b3b;
	font-size: 24px;
	font-weight: 500;
}

.pricing-table h5 {
	text-align: center;
	color: #B3B3B3;
	font-size: 36px;
	font-weight: 400;
}

.pricing-table .pricing-features {
	margin-top: 2rem;
}

.pricing-table .pricing-features  {
	font-size: 14px;
	margin: .5rem 0;
	color: #B3B3B3;
	display: flex;
	justify-content: space-between;
}

.pricing-table .pricing-features {
	display: inline-block;
	color: #3b3b3b;
	width: 100%;
	font-weight: 500;
}

.pricing-table 	.price-tag {
	margin-top: 2rem;
	text-align: center;
	font-weight: 500;
}

.pricing-table .price-tag .symbol {
	font-size: 24px;
}

.pricing-table .price-tag .amount {
	letter-spacing: -2px;
	font-size: 64px;
}

.pricing-table .price-tag .after {
	color: #3b3b3b;
	font-weight: 500;
}

.pricing-table .price-button {
	display: block;
	color: #fff;
	margin-top: 2rem;
	padding: .75rem;
	border-radius: 2px;
	text-align: center;
	font-weight: 500;
	transition: .3s;
	cursor: pointer;
}

.pricing-table .price-button:hover {
	text-decoration: none;
}

.purple .pricing-label {
	background: #cad2ff;
	color: #627afe;
}

.purple .price-tag {
	color: #627afe;
}

.purple .price-button {
	background: #627afe;
}

.purple .price-button:hover {
	background: #546dfe;
}

.turquoise .pricing-label {
	background: #b9edee;
	color: #44cdd2;
}

.turquoise .price-tag {
	color: #44cdd2;
}

.turquoise .price-button {
	background: #44cdd2;
}

.turquoise .price-button:hover {
	background: #2dbcc4;
}

.red .pricing-label {
	background: #ffc4c4;
	color: #ff5e5e;
}

.red .price-tag {
	color: #ff5e5e;
}

.red .price-button {
	background: #ff5e5e;
}

.red .price-button:hover {
	background: #f23c3c;
}
.feature{
	display: grid;
}
.deviceTitle{
	display: flex;
	justify-content: space-around;
}
.paraName{
	display: flex;
	justify-content: space-between;
	width: 100%;
	/* align-items: center; */
}