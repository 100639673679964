*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{
    padding: 12px !important;
}
/* .adminContent{
    display: grid;
    grid-gap: 35px;
    place-items: center;
} */
.subsHeading{
    font-size: 24px;
    color: rgba(21,29,86,255);
    font-weight: 200;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.selectFields_password{
    display: grid;  
    grid-gap: 20px;
    width: 350px;
   font-size: 20px;
}

.Plan{
 display: flex;
 justify-content: space-around;
}
.Amount{
    display: flex;
    justify-content: space-around;
}
.input{
    width: 144.4px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    border: 1px solid black;
    border-radius: 5px;
    background-color: rgba(49,167,203,255);
    color: white;
    text-align: center;
}
.Device{
    display: flex;
    justify-content: space-around;
}
.labels{
    width: 80px;
    font-family: cursive;
    font-weight: bold;
}
.btnDiv{
   margin-top: 10px;
}

.subsBtn{
  padding: 5px 30px 5px 30px;
  border-radius: 5px;
  background-color: rgba(49,167,203,255);
  cursor: pointer;
  color: white;
}

.closeIcon:hover{
     color:black;
}

.btnDiv{
    margin-top: 20px;
}

.crossIcon-btn:hover{
    cursor: pointer;
}