*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.modalDiv{
   display: grid;
   grid-gap: 10px;
   place-items: center;
}
.modalHeading{
    font-size: 40px;
    color: rgba(21,29,86,255);
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
}
.modalPara{
  font-size: 20px;
  width: 450px;
  flex-wrap: wrap;
  font-family: Georgia, 'Times New Roman', Times, serif;
}
.codeSection{
   font-size: 30px;
}
.sendAgainBtn{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
  color: rgba(49,167,203,255);
  cursor: pointer;
}
.modalBtns{
    display: flex;
}
.spinnerDiv{
  display: grid;
  place-items: center;
}
.cancel{
    background-color: red;
  color: white;
  padding: 5px 25px 5px 25px;
  border-radius: 15px;
  cursor: pointer;
}
.verify{
  background-color: rgba(21,29,86,255);
  color: white;
  padding: 5px 25px 5px 25px;
  border-radius: 15px;
  cursor: pointer;
  margin-left: 5px;
}
.failedDiv{
 display: grid;
 grid-gap: 5px;
 place-items: center;
}
.failedImageDiv{
  border-radius: 50%;
  background-color: red;
}
.failedHeading{
  font-size: 30px;
  margin-top: 10px;
  font-family: monospace;
  color: red;
  font-weight: bold;
}
.redirectingPara{
  font-size: 20px;
  font-family: monospace;
}