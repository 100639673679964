body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Updated css */

.css-1sn4lm3-MuiTypography-root{
  margin: 16px !important;
}

.css-dmmspl-MuiFormGroup-root{
  margin-left: 25px !important;
  margin-top: 0px !important;
}

/* .MuiTable-root tss-900muf-MUIDataTable-tableRoot css-rqglhn-MuiTable-root :nth-child(even){
    background: gray
} */

.MuiTableColumn-root :nth-child(odd){
  background: gray;
}

.css-15wwp11-MuiTableHead-root{
  background-color: blueviolet;
}

tr:nth-child(odd){
  background: #f3f7f8;
}

.sub_edit:hover{
  cursor: pointer;
}